

























































































































import {Component, Vue} from "vue-property-decorator";
import api from "@/api";
import {ServiceOrganizationDto, ServicesObjectDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import ImportExcel from "@/components/ImportExcel/index.vue";

@Component({
  components: {
    PagedTableView,
    ExportButton,
    ImportExcel,
  },
})
export default class ServiceObjectList extends Vue {
  editId = 0;
  queryForm = {
    name: undefined,
    phone: "",
    serviceOrganization: "",
  };
  serviceOrganizationList: ServiceOrganizationDto[] = [];
  exportFieldList =
    [
      "姓名",
      "所属服务机构",
      "服务对象类型",
      "性别",
      "出生日期",
      "手机号码",
      "省",
      "市",
      "区",
      "详细地址",
      "身体状况",
      "其他身体状况",
      "备注",
      "创建日期"
    ];

  created() {
    this.fetchData(this.queryForm);
    this.fetchServiceOrgList();
  }

  fetchData(params: any) {
    return api.serviceObject.getAll(params);
  }

  fetchServiceOrgList() {
    api.serviceOrganization.getAllServiceOrganizationList().then((res) => {
      this.serviceOrganizationList = [...res!];
      debugger;
    });
  }

  handleCreate() {
    this.$router.push({
      name: "serviceObjectCreate",
    });
  }

  jumpDetail(index: number, row: ServicesObjectDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "serviceObjectDetail",
      params: {id: row.id!.toString()},
    });
  }

  handleEdit(index: number, row: ServicesObjectDto) {
    this.$router.push({
      name: "serviceObjectEdit",
      params: {id: row.id!.toString()},
    });
  }

  handleDelete(index: number, row: ServicesObjectDto) {
    this.$confirm("您确认要删除服务对象吗？", "提示").then(() => {
      api.serviceObject.delete({id: row.id}).then(() => {
        this.$message.success("删除成功!");
        this.fetchData(this.queryForm);
      });
    });
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
